<template>
    <v-menu
        bottom
        :close-on-content-click="false"
        dense>
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                :small="small"
                title="Colors & Styles">
                <v-icon :small="small">mdi-theme-light-dark</v-icon>
            </v-btn>
        </template>

        <v-list dense max-height="450px">
            <v-subheader>Styles & Colors
                <v-spacer />
                <v-btn small text @click="() => $BlitzIt.style.resetToDefaultColors($vuetify)">Reset</v-btn>
            </v-subheader>
            <v-list-item @click="$BlitzIt.style.toggleLightDark($vuetify)">
                <v-list-item-icon><v-icon>mdi-theme-light-dark</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Toggle Light/Dark Mode</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="my-1" />
            <v-subheader>Custom</v-subheader>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Primary Color</v-list-item-title>
                    <v-list-item-subtitle>{{ currentColorSet.primary }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <BT-Color-Picker-Menu
                        buttonClass="primary"
                        v-model="currentColorSet.primary"
                        @change="() => $BlitzIt.style.resetColors($vuetify)" />
                </v-list-item-action>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Secondary Color</v-list-item-title>
                    <v-list-item-subtitle>{{ currentColorSet.secondary }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <BT-Color-Picker-Menu 
                        buttonClass="secondary"
                        v-model="currentColorSet.secondary"
                        @change="() => $BlitzIt.style.resetColors($vuetify)" />
                </v-list-item-action>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>Accent Color</v-list-item-title>
                    <v-list-item-subtitle>{{ currentColorSet.accent }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <BT-Color-Picker-Menu
                        buttonClass="accent"
                        v-model="currentColorSet.accent"
                        @change="() => $BlitzIt.style.resetColors($vuetify)" />
                </v-list-item-action>
            </v-list-item>
            <v-subheader>Templates</v-subheader>
            <v-card>
                <v-row dense v-for="(color, ind) in colors" :key="ind" @click="selectTemplate(color)">
                    <v-col cols="4">
                        <v-btn :color="color.primary" />
                    </v-col>
                    <v-col cols="4">
                        <v-btn :color="color.secondary" />
                    </v-col>
                    <v-col cols="4">
                        <v-btn :color="color.accent" />
                    </v-col>
                    <v-divider class="my-1" />
                </v-row>
            </v-card>
            
        </v-list>
        
    </v-menu>
</template>

<script>
    import colors from 'vuetify/lib/util/colors';
    export default {
        data() {
            return {
                colors: [
                    { 
                        primary: '#192233',
                        secondary: '#192233',
                        accent: '#7fcbf7'
                    },
                    { 
                        primary: colors.pink.darken2,
                        secondary: colors.pink.base,
                        accent: colors.pink.base
                    },
                    { 
                        primary: colors.purple.darken2,
                        secondary: colors.purple.base,
                        accent: colors.purple.base
                    },
                    { 
                        primary: colors.indigo.darken2,
                        secondary: colors.indigo.base,
                        accent: colors.indigo.base
                    },
                    { 
                        primary: colors.teal.darken2,
                        secondary: colors.teal.base,
                        accent: colors.teal.base
                    },
                    { 
                        primary: colors.green.darken2,
                        secondary: colors.green.base,
                        accent: colors.green.base
                    },
                    { 
                        primary: colors.lightGreen.darken2,
                        secondary: colors.lightGreen.base,
                        accent: colors.lightGreen.base
                    },
                    { 
                        primary: colors.orange.darken2,
                        secondary: colors.orange.base,
                        accent: colors.orange.base
                    },
                    { 
                        primary: colors.blueGrey.darken2,
                        secondary: colors.blueGrey.base,
                        accent: colors.blueGrey.base
                    },
                    { 
                        primary: colors.red.darken2,
                        secondary: colors.red.base,
                        accent: colors.red.base
                    }
                ]
            }
        },
        props: {
            small: {
                type: Boolean,
                default: true
            }
        },
        components: {
            BTColorPickerMenu: () => import('~components/BT-Color-Picker-Menu.vue')
        },
        computed: {
            currentColorSet() {
                return this.$vuetify.theme.dark ? this.$BlitzIt.style.root.cosmetics.dark : this.$BlitzIt.style.root.cosmetics.light
            }
        },
        methods: {
            selectTemplate(t) {
                this.currentColorSet.primary = t.primary;
                this.currentColorSet.secondary = t.secondary;
                this.currentColorSet.accent = t.accent;
                this.$BlitzIt.style.resetColors(this.$vuetify);
            }
        }
    }
</script>